<template>
  <tab-main-view
    :loader="loading"
    :config="config"
    :item="showItem"
    :fetch="fetch"
    :module="'people'"
    :route="'people'"
    :method="'patch'"
    :item-id="this.$route.params.id"
  />
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import TabMainView from '@/global/components/view-layouts/tab-layout/TabMainView.vue'

const { mapGetters, mapActions } = createNamespacedHelpers('people/person')
export default {
  name: 'PersonView',

  components: {
    TabMainView
  },

  props: {
    module: {
      type: String,
      default: 'people'
    },
    model: {
      type: String,
      default: 'person'
    }
  },

  data () {
    return {
      loading: true
    }
  },

  computed: {
    ...mapGetters(['showItem', 'viewConfig']),

    config () {
      return {
        mainViewConfig: {
          tabsConfig: {
            driverDetails: {
              label: this.$t('people/person.details'),
              editable: true,
              visible: true,
              view: {
                rows: [
                  {
                    columns: [
                      {
                        layout: 'label-value',
                        items: [
                          {
                            key: 'avatar',
                            label: this.$t('people/person.image'),
                            type: 'image_preview',
                            max_images: 1,
                            visible: true,
                            editable: true
                          }
                        ]
                      }
                    ]
                  },
                  {
                    columns: [
                      {
                        layout: 'label-value',
                        items: [
                          {
                            key: 'record_number',
                            label: this.$t('people/person.record_number'),
                            type: 'text',
                            editable_icon: 'mdi-pencil',
                            visible: true,
                            editable: true
                          },
                          {
                            key: 'first_name',
                            label: this.$t('people/person.first_name'),
                            type: 'text',
                            editable_icon: 'mdi-pencil',
                            visible: true,
                            editable: true
                          },
                          {
                            key: 'last_name',
                            label: this.$t('people/person.last_name'),
                            type: 'text',
                            editable_icon: 'mdi-pencil',
                            visible: true,
                            editable: true
                          },
                          {
                            key: 'group',
                            label: this.$t('people/person.group'),
                            type: 'autocomplete',
                            editable_icon: 'mdi-pencil',
                            autocomplete_options: {
                              module: 'people',
                              route: 'groups/autocomplete'
                            },
                            visible: true,
                            editable: true
                          },
                          {
                            key: 'person_working_place',
                            label: this.$t('people/person.workplace'),
                            type: 'autocomplete',
                            editable_icon: 'mdi-pencil',
                            autocomplete_options: {
                              module: 'people',
                              route: 'working-place/autocomplete'
                            },
                            visible: true,
                            editable: true
                          }
                        ]
                      },
                      {
                        layout: 'label-value',
                        items: [
                          {
                            key: 'person_occupation',
                            label: this.$t('people/person.occupation'),
                            type: 'autocomplete',
                            editable_icon: 'mdi-pencil',
                            autocomplete_options: {
                              module: 'people',
                              route: 'person-occupation/autocomplete'
                            },
                            visible: true,
                            editable: true
                          },
                          {
                            key: 'phone_number',
                            label: this.$t('people/person.phone_number'),
                            type: 'text',
                            editable_icon: 'mdi-pencil',
                            visible: true,
                            editable: true
                          },
                          {
                            key: 'phone_number_2',
                            label: this.$t('people/person.phone_number_2'),
                            type: 'text',
                            editable_icon: 'mdi-pencil',
                            visible: true,
                            editable: true
                          },
                          {
                            key: 'email',
                            label: this.$t('people/person.email'),
                            type: 'text',
                            editable_icon: 'mdi-pencil',
                            visible: true,
                            editable: true
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            },
            workingHours: {
              label: this.$t('people/person.working_hours'),
              editable: true,
              visible: true,
              view: {
                rows: [
                  {
                    columns: [
                      {
                        layout: 'collection-objects',
                        collection_name: 'working_hours',
                        row_labels: [this.$t('base.monday'), this.$t('base.tuesday'), this.$t('base.wednesday'), this.$t('base.thursday'), this.$t('base.friday'), this.$t('base.saturday'), this.$t('base.sunday')],
                        headers: [this.$t('base.day'), this.$t('people/person.beginning'), this.$t('people/person.end'), this.$t('people/person.break_from'), this.$t('people/person.break_to')],
                        object_properties: [
                          {
                            key: 'from',
                            type: 'time',
                            editable_icon: 'mdi-pencil',
                            default_value: null,
                            visible: true,
                            editable: true
                          },
                          {
                            key: 'to',
                            type: 'time',
                            editable_icon: 'mdi-pencil',
                            default_value: null,
                            visible: true,
                            editable: true
                          },
                          {
                            key: 'break_from',
                            type: 'time',
                            editable_icon: 'mdi-pencil',
                            default_value: null,
                            visible: true,
                            editable: true
                          },
                          {
                            key: 'break_to',
                            type: 'time',
                            editable_icon: 'mdi-pencil',
                            default_value: null,
                            visible: true,
                            editable: true
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            },
            cards: {
              label: this.$t('people/person.cards'),
              visible: true,
              view: {
                rows: [
                  {
                    columns: [
                      {
                        layout: 'data-table',
                        headers: [
                          {
                            text: this.$t('people/person.id_card_type'),
                            value: 'type',
                            align: 'left',
                            sortable: false
                          },
                          {
                            text: this.$t('people/person.pin_code'),
                            value: 'value',
                            align: 'left',
                            sortable: false
                          },
                          {
                            text: this.$t('base.actions'),
                            value: 'actions',
                            align: 'center',
                            sortable: false
                          }
                        ],
                        display_data_config: {
                          type: {
                            type: 'custom_html',
                            html: (item) => `${
                              item.type && item.type.name ? item.type.name : ''
                            }`,
                            custom_value: (item) => {
                              return item
                            }
                          }
                        },
                        rowPointer: true,
                        buttons: [
                          {
                            text: this.$t('base.add_new_row'),
                            prependIcon: 'mdi mdi-plus-circle',
                            iconClass: 'success--text mr-3'
                          }
                        ],
                        title: this.$t('people/person.cards'),
                        detailsConfig: {
                          columns: [
                            {
                              items: [
                                {
                                  key: 'id',
                                  label: this.$t('people/person.id_card_type'),
                                  type: 'autocomplete',
                                  autocomplete_options: {
                                    module: 'people',
                                    route: 'id-cards/autocomplete'
                                  },
                                  visible: true,
                                  editable: true
                                }
                              ]
                            }
                          ],
                          apiConfig: {
                            module: 'people',
                            route: 'person-cards',
                            parent_id_name: 'person_id'
                          }
                        },
                        table_filters: [
                          {
                            key: 'type',
                            label: this.$t('people/person.id_card_type'),
                            type: 'autocomplete',
                            autocomplete_options: {
                              module: 'people',
                              route: 'id-card-types/autocomplete',
                              custom_display_value: (item) => {
                                return item.name
                              }
                            },
                            visible: true,
                            editable: true
                          },
                          {
                            key: 'value',
                            label: this.$t('people/person.pin_code'),
                            type: 'text',
                            field_type: 'number',
                            visible: true,
                            editable: true
                          }
                        ],
                        actions: [
                          {
                            key: 'edit',
                            text: this.$t('base.edit'),
                            icon: 'mdi mdi-pencil'
                          },
                          {
                            key: 'delete',
                            text: this.$t('base.delete'),
                            icon: 'mdi mdi-delete',
                            apiConfig: {
                              module: 'people',
                              route: 'person-cards/',
                              method: 'delete'
                            },
                            dialogMessage: this.$t('people/person.delete_card_dialog_message'),
                            dialogTitle: this.$t('people/person.delete_card_dialog_title')
                          }
                        ],
                        pagination_numbers: [5, 10, 50, 100],
                        indexApiConfig: {
                          module: 'people',
                          route: 'person-cards',
                          parent_id_name: 'person_id'
                        }
                      }
                    ]
                  }
                ]
              }
            },
            person_documents: {
              label: this.$t('people/person.documents'),
              visible: true,
              view: {
                rows: [
                  {
                    columns: [
                      {
                        layout: 'data-table',
                        headers: [
                          {
                            text: this.$t('people/person_document.type'),
                            value: 'person_document_id',
                            align: 'left',
                            sortable: false
                          },
                          {
                            text: this.$t('people/person_document.show_table_valid_from'),
                            value: 'valid_from',
                            align: 'left',
                            sortable: false
                          },
                          {
                            text: this.$t('people/person_document.show_table_valid_to'),
                            value: 'valid_to',
                            align: 'left',
                            sortable: false
                          },
                          {
                            text: this.$t('people/person_document.alarm_on'),
                            value: 'alarm_on',
                            align: 'center',
                            sortable: false
                          },
                          {
                            text: this.$t('base.actions'),
                            value: 'actions',
                            align: 'center',
                            sortable: false
                          }
                        ],
                        display_data_config: {
                          person_document_id: {
                            type: 'custom_html',
                            html: (item) => `${
                              item.person_document_id && item.person_document_id.display_name ? item.person_document_id.display_name : ''
                            }`,
                            custom_value: (item) => {
                              return item
                            }
                          },
                          alarm_on: {
                            type: 'boolean'
                          }
                        },
                        rowPointer: true,
                        buttons: [
                          {
                            text: this.$t('base.add_new_row'),
                            prependIcon: 'mdi mdi-plus-circle',
                            iconClass: 'success--text mr-3'
                          }
                        ],
                        title: this.$t('people/person.documents'),
                        detailsConfig: {
                          columns: [
                            {
                              items: [
                                {
                                  key: 'person_document_id',
                                  label: this.$t('people/person_document.type'),
                                  type: 'autocomplete',
                                  autocomplete_options: {
                                    module: 'people',
                                    route: 'person-documents/autocomplete'
                                  },
                                  visible: true,
                                  editable: true
                                },
                                {
                                  key: 'valid_from',
                                  label: this.$t('people/person_document.show_table_valid_from'),
                                  type: 'date',
                                  visible: true,
                                  editable: true
                                },
                                {
                                  key: 'valid_to',
                                  label: this.$t('people/person_document.show_table_valid_to'),
                                  type: 'date',
                                  visible: true,
                                  editable: true
                                },
                                {
                                  key: 'alarm_on',
                                  label: this.$t('people/person_document.alarm_on'),
                                  type: 'switch',
                                  visible: true,
                                  editable: true
                                }
                              ]
                            }
                          ],
                          apiConfig: {
                            module: 'people',
                            route: 'person-documents',
                            parent_id_name: 'person_id'
                          }
                        },
                        table_filters: [
                          {
                            key: 'type',
                            label: this.$t('people/person_document.type'),
                            type: 'autocomplete',
                            autocomplete_options: {
                              module: 'people',
                              route: 'person-documents/autocomplete',
                              custom_display_value: (item) => {
                                return item.name
                              }
                            },
                            visible: true,
                            editable: true
                          },
                          {
                            key: 'valid_from',
                            label: this.$t('people/person_document.show_table_valid_from'),
                            type: 'date',
                            field_type: 'date',
                            visible: true,
                            editable: true
                          },
                          {
                            key: 'valid_to',
                            label: this.$t('people/person_document.show_table_valid_to'),
                            type: 'date',
                            field_type: 'date',
                            visible: true,
                            editable: true
                          }
                        ],
                        actions: [
                          {
                            key: 'edit',
                            text: this.$t('base.edit'),
                            icon: 'mdi mdi-pencil'
                          },
                          {
                            key: 'delete',
                            text: this.$t('base.delete'),
                            icon: 'mdi mdi-delete',
                            apiConfig: {
                              module: 'people',
                              route: 'person-documents/',
                              method: 'delete'
                            },
                            dialogMessage: this.$t('people/person.delete_document_dialog_message'),
                            dialogTitle: this.$t('people/person.delete_document_dialog_title')
                          }
                        ],
                        pagination_numbers: [5, 10, 50, 100],
                        indexApiConfig: {
                          module: 'people',
                          route: 'person-documents',
                          parent_id_name: 'person_id'
                        }
                      }
                    ]
                  }
                ]
              }
            }
          }
        },
        breadCrumbsConfig: {
          prependIcon: {
            value: 'mdi mdi-car'
          },
          delimeter: '>',
          parts: [
            {
              value: this.$t('people.drivers')
            },
            {
              value: this.$t('people/person.driver_overview')
            },
            {
              value: this.showItem?.full_name
            }
          ]
        },
        buttons: {
          back: {},
          edit: {},
          cancel: {},
          save: {}
        },
        files_config: {
          avatar: {
            type: 'image'
          }
        },
        autocompletes_values: {
          cards: {
            type: 'array',
            objectPropertyContainingValue: 'id_card_id',
            value: 'id'
          },
          group: {
            type: 'object',
            value: 'id'
          },
          person_occupation: {
            type: 'object',
            value: 'id'
          },
          person_working_place: {
            type: 'object',
            value: 'id'
          }
        }
      }
    }
  },

  async created () {
    await this.fetch(this.$route.params.id)
    this.loading = false
  },

  methods: {
    ...mapActions(['fetch'])
  }
}
</script>
